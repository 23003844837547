@import 'autoload';

.schools {
  position: relative;

  ul {
    margin-left: -15px;
    margin-right: -15px;
    position: inherit;

    .slick-prev,
    .slick-next {
      align-items: center;
      background: $grey;
      border-radius: 50%;
      color: $white;
      display: flex;
      font-size: 1.2rem;
      height: 25px;
      justify-content: center;
      position: absolute;
      transition-duration: $transition-duration;
      width: 25px;

      &::before {
        content: none;
      }

      &:hover {
        background-color: $green;
      }
    }

    .slick-prev {
      left: auto;
      right: 30px;
      top: 15px;
    }

    .slick-next {
      right: 0;
      top: 15px;
    }

    li {
      opacity: .8;
      text-align: center;
      transition-duration: $transition-duration;

      &:hover {
        opacity: 1;
      }

      a {
        display: block;
      }

      img {
        display: inline-block;
        width: calc(100% - 30px);

        @media #{$smartphone} {
          width: 100%;
        }
      }
    }
  }
}

.banners img {
  border-radius: 5px;
  width: 100%;
}
